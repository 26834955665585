import './App.css';
import './ProjectImg.css';
import UI from './components/UI';

function App() {
  return (
    <UI/>
  )
}

export default App;